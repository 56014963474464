<template>
  <div>
    <el-dialog
      :visible.sync="isDialog"
      append-to-body
      :width="width + '%'"
      :before-close="cancellationFn"
    >
      <div slot="title" style="color: #409eff">操作记录</div>
      <el-table
        v-loading="loading"
        :data="projectLogList"
        style="width: 100%"
        max-height="400"
        border
      >
        <el-table-column type="index" label="序号" width="70" align="center"></el-table-column>
        <el-table-column prop="createdDate" min-width="130" label="操作日期" align="center">
          <template slot-scope="scope">
            {{ scope.row.createdDate | timeFormat }}
          </template>
        </el-table-column>
        <el-table-column align="center" min-width="110" label="操作人员" prop="createName">
        </el-table-column>
        <el-table-column align="center" label="操作" width="100">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="DetailFn(scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <span name="footer" slot="footer" style="display: flex; justify-content: center">
        <el-button
          type="primary"
          style="min-width: 120px; margin: 0 25px"
          plain
          :loading="loading"
          @click="cancellationFn"
          >返 回</el-button
        >
      </span>
    </el-dialog>

    <ProjectDetails :isShow.sync="projectIsShow" :options="projectOptions" />
  </div>
</template>

<script>
export default {
  components: {
    ProjectDetails: () => import('./project-details.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: 60,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      isDialog: false,
      loading: false,
      projectLogList: [],
      projectOptions: {}, //项目详情
      projectIsShow: false, //项目详情弹窗
    }
  },
  watch: {
    isShow: {
      immediate: true,
      handler: function (newVal) {
        if (newVal) {
          this.getData()
        }
        this.isDialog = newVal
      },
    },
  },
  computed: {},
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return 0
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    // 详情
    DetailFn(row) {
      this.projectOptions = { ...row }
      this.projectIsShow = true
    },
    getData() {
      this.loading = true
      this.$api.log
        .list({
          professionalId: this.options.id,
        })
        .then(res => {
          this.projectLogList = []
          this.projectLogList = res.data ? res.data : []

          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    cancellationFn() {
      this.loading = true
      this.$emit('update:isShow', false)
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped></style>
